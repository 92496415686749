<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			:width="dialogWidth"
			class="col-8 ml-auto mr-auto">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					:color="color"
					elevation="1"
					fab
					small
					v-bind="attrs"
					v-on="on"
					@click="loadData">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title
					class="headline primary"
					v-if="unusedLevelList">
					Add Bonus Level
				</v-card-title>

				<v-card-text class="ma-0 pa-0">
					<v-stepper
						v-model="formGuide"
						elevation="0">
						<v-stepper-header class="px-10">
							<v-stepper-step
								:complete="isStepValid[0]"
								:editable="tmpEditable"
								:step="1">
								Joker Config
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								:complete="isStepValid[1]"
								:editable="tmpEditable"
								:step="2">
								Joker Bonus
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								:complete="isStepValid[2]"
								:editable="tmpEditable"
								:step="3">
								Joker Scheme
							</v-stepper-step>
						</v-stepper-header>

						<v-stepper-items
							:style="'min-height: ' + stepperItemsHeight"
							class="d-flex justify-center align-center">
							<v-stepper-content
								class="pa-2 pa-md-4"
								key="jokerConfigForm"
								:step="1">
								<v-card
									v-if="formGuide == 1"
									class="pa-2 ma-0">
									<v-row>
										<v-col
											cols="12"
											sm="6"
											xl="6"
											class="d-flex flex-column align-self-start">
											<v-select
												v-if="jokerBonusConfigsList"
												:items="jokerBonusConfigsList"
												v-model="selectedJBConfig"
												@input="onSelectedJBConfig"
												item-value="id"
												item-text="name"
												label="Select Jackpot Config"
												:rules="[rules.required]"
												return-object></v-select>
										</v-col>
										<v-col
											cols="12"
											sm="5"
											md="4"
											lg="3"
											xl="2">
											<v-text-field
												:value="groupContribType | Capitalize"
												disabled
												label="Contribution Type">
												<template v-slot:append-outer>
													<Tooltip
														:iconSize="'22px'"
														:text="`Inherited from group config`" />
												</template>
											</v-text-field>
										</v-col>
										<v-col v-if="jokerBonusListByConfgId.length > 1">
											Joker Bonus List using the same config:
											<div
												v-for="jokerBonus of jokerBonusListByConfgId"
												v-bind:key="jokerBonus.id"
												class="red--text">
												id : {{ jokerBonus.id }}
											</div>
										</v-col>
									</v-row>

									<JokerBonusConfigForm
										class="mb-2"
										:value="selectedJBConfigCopy"
										@input="JBConfigUpdate"
										:contribTypeOfGroup="groupContribType"
										:disableForm="preMadeConfig"></JokerBonusConfigForm>
								</v-card>
							</v-stepper-content>
							<v-stepper-content
								key="jokerBonusForm"
								:step="2">
								<v-card
									class="col-12 col-md-6 col-lg-5 col-xl-4 pa-4 mx-auto my-auto d-flex flex-column justify-space-around"
									v-if="formGuide == 2">
									<v-row>
										<v-col cols="4">
											<v-text-field
												label="Min Value"
												disabled
												v-model="selectedJBConfigCopy.min_value"
												type="number" />
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Max Value"
												disabled
												v-model="selectedJBConfigCopy.max_value"
												type="number" />
										</v-col>
										<v-col cols="4">
											<v-text-field
												label="Min Bonus"
												disabled
												v-model="selectedJBConfigCopy.min_jack"
												type="number" />
										</v-col>
									</v-row>
									<joker-bonus-form
										:value="newJokerBonusCopy"
										@input="JBUpdate"></joker-bonus-form>
								</v-card>
							</v-stepper-content>

							<v-stepper-content
								key="jokerPreviewForm"
								:step="3"
								class="pa-4 pa-md-2">
								<v-card
									v-if="formGuide == 3"
									:min-height="stepperContentHeight"
									class="d-flex align-start flex-wrap justify-center text-center px-0 py-4">
									<v-tooltip color="error darken-2">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn
												class="align-center mt-4"
												fab
												v-if="sameThemeGroups.length > 1"
												elevation="0"
												color="red"
												x-small
												v-on="tooltip">
												<v-icon>mdi-exclamation</v-icon>
											</v-btn>
										</template>
										<span>
											<h4>Groups using this theme:</h4>
											<ul>
												<li
													v-for="groups of sameThemeGroups"
													v-bind:key="groups.id"
													class="text-center">
													{{ groups.name }} (ID: {{ groups.id }})
												</li>
											</ul>
										</span>
									</v-tooltip>
									<v-select
										v-if="levelDropdown"
										:items="levelDropdown"
										v-model="selectedLevel"
										item-value="name"
										item-text="name"
										label="Select Joker Bonus Level"
										:rules="[rules.required]"
										return-object
										class="col-12 col-md-6 col-lg-4 px-4 pt-4 ma-0"></v-select>

									<div
										v-else
										class="col-2 pa-4">
										THERE ARE NO FREE LEVELS TO CREATE FORM
									</div>
									<v-spacer></v-spacer>

									<edit-scheme
										class="col-12"
										:schemeToEdit="groupThemeCopy.setting[selectedLevel.name]"
										:schemeName="selectedLevel.name"
										v-on:borderChange="updateBorder"
										v-on:backgroundChange="updateBackground"
										v-on:textChange="updateText"
										v-on:iconTintChange="updateIconTint"
										v-on:animationTintChange="updateAnimationTint"
										v-on:textStrokeChange="updateTextStroke"
										v-on:visibleNameChange="updateVisibleName"
										v-on:iconUrlChange="updateIconUrl"></edit-scheme>
								</v-card>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card-text>
				<v-divider />
				<v-card-actions
					class="stickyButtons"
					col="12">
					<v-btn
						text
						@click="cancelDialog">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						color="info"
						text
						@click="previousStep(formGuide)"
						:disabled="formGuide < 2">
						Back
					</v-btn>
					<v-btn
						v-if="formGuide === 3"
						color="primary"
						text
						@click="submit"
						:disabled="!jbFormToSubmit">
						Submit
					</v-btn>
					<v-btn
						v-else
						color="primary"
						@click="nextStep(formGuide)"
						:disabled="!isStepValid[formGuide - 1]">
						Next
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JokerBonusForm from "./JokerBonusForm.vue";
import JokerBonusConfigForm from "../jokerBonusConfig/JokerBonusConfigForm.vue";
import EditScheme from "../theme/EditScheme.vue";
import { rules as defaultRules } from "../../../plugins/rules.ts";
import Tooltip from "../../shared/LoganTooltip";

export default {
	components: {
		JokerBonusForm,
		JokerBonusConfigForm,
		EditScheme,
		Tooltip,
	},

	props: {
		buttonTitle: {
			type: String,
			default: "Add New",
		},
		bonusGroupId: {
			type: [String, Number],
		},
		bonusGroupName: {
			type: String,
		},
		groupContribType: {
			type: String,
		},
		color: {
			type: String,
			default: "primary",
		},
		unusedLevelList: {
			type: Array,
		},
		levels: {
			type: Array,
		},
	},

	data() {
		const blankConfig = { id: -1, name: "New_JB_Config" };
		return {
			selectedJBConfigCopy: {},
			newJokerBonusCopy: {},

			tmpComplete: false,
			tmpEditable: false,

			//todo
			groupThemeCopy: {
				setting: {
					none: {
						borderColors: [],
						borderColorStops: [],
						backgroundColors: [],
						backgroundColorStops: [],
						textColors: [],
						textColorStops: [],
						textStroke: "",
						animationTint: "",
						iconTint: "",
						iconUrl: "",
						visible_name: "",
					},
				},
			},
			selectedLevel: {
				id: -1,
				name: "",
			},
			jokerBonusListByConfgId: [],
			jokerBonusConfigsList: [],
			blankConfig,

			newJokerBonus: {
				group_id: this.$route.params.jackpot_group_id,
				level_id: 0,
				jackpot_config_id: 0,
			},

			selectedJBConfig: blankConfig,
			disableConfigForm: false,

			jbFormToSubmit: false,

			sameThemeGroups: [],
			formGuide: 1,
			isStepValid: [false, false, false],
			rules: {
				...defaultRules,
			},
			dialog: false,
			dialogWidth: "75%",
			stepperContentHeight: "60vh",
			stepperItemsHeight: "70vh",
		};
	},

	computed: {
		...mapGetters([
			"createdJokerBonus",
			"getSpecificTheme",
			"getCreatedTheme",
			"getDefaultScheme",
			"getFilteredJBConfigsList",
			"getFilteredJackpotGroupList",
			"filteredJokerBonusList",
			"getCreatedJBConfig",
			"jackpotGroupDetail",
		]),

		preMadeConfig() {
			return this.selectedJBConfig.id > -1;
		},
		levelDropdown() {
			return this.unusedLevelList.map((level) => ({
				id: level.id,
				name: level.name,
			}));
		},
	},

	methods: {
		...mapActions("notification", ["setMessage"]),
		...mapActions([
			"createJokerBonus",
			"createTheme",
			"loadJackpotGroupDetail",
			"loadFilteredGroupJackpots",
			"loadFilteredJackpotGroupList",
			"loadFilteredJokerBonusList",
			"loadGroupThemeSetting",
			"loadSpecificTheme",
			"loadJBConfigs",
			"loadFilteredJBConfigs",
			"setGroupTheme",
			"addJBConfig",
			"addJBConfigNew",
		]),

		updateBorder(attr) {
			this.createSchemeInTheme();
			[
				this.groupThemeCopy.setting[this.selectedLevel.name]["borderColors"],
				this.groupThemeCopy.setting[this.selectedLevel.name][
					"borderColorStops"
				],
			] = attr;
		},

		updateBackground(attr) {
			this.createSchemeInTheme();
			[
				this.groupThemeCopy.setting[this.selectedLevel.name][
					"backgroundColors"
				],
				this.groupThemeCopy.setting[this.selectedLevel.name][
					"backgroundColorStops"
				],
			] = attr;
		},

		updateText(attr) {
			this.createSchemeInTheme();
			[
				this.groupThemeCopy.setting[this.selectedLevel.name]["textColors"],
				this.groupThemeCopy.setting[this.selectedLevel.name]["textColorStops"],
			] = attr;
		},

		updateIconTint(c) {
			this.createSchemeInTheme();
			this.groupThemeCopy.setting[this.selectedLevel.name]["iconTint"] = c.hex;
		},

		updateAnimationTint(c) {
			this.createSchemeInTheme();
			this.groupThemeCopy.setting[this.selectedLevel.name]["animationTint"] =
				c.hex;
		},

		updateTextStroke(c) {
			this.createSchemeInTheme();
			this.groupThemeCopy.setting[this.selectedLevel.name]["textStroke"] =
				c.hex;
		},

		updateVisibleName(v) {
			this.createSchemeInTheme();
			this.groupThemeCopy.setting[this.selectedLevel.name]["visible_name"] = v;
		},

		updateIconUrl(v) {
			this.createSchemeInTheme();
			this.groupThemeCopy.setting[this.selectedLevel.name]["iconUrl"] = v;
		},

		createSchemeInTheme() {
			if (
				!this.groupThemeCopy.setting[this.selectedLevel.name] &&
				this.selectedLevel.name != "" &&
				this.selectedLevel.id > -1
			) {
				this.groupThemeCopy.setting[this.selectedLevel.name] = {};
			}
		},

		cancelDialog() {
			this.selectedJBConfig = this.blankConfig;
			this.selectedLevel = {
				id: -1,
				name: "",
			};
			this.formGuide = 1;
			this.isStepValid = [false, false, false];
			this.dialog = false;
		},

		async submit() {
			let tmpThemeOrig = {};
			if (this.getSpecificTheme.data?.setting) {
				tmpThemeOrig = this.copyNConvertTheme(this.getSpecificTheme.data); //JSON.parse(JSON.stringify( this.getSpecificTheme.data ))
			} else {
				tmpThemeOrig = this.createNewTheme();
			}

			let tmpThemeUnchanged = this.equalObjects(
				this.groupThemeCopy.setting[this.selectedLevel.name],
				tmpThemeOrig.setting[this.selectedLevel.name]
			);

			if (!tmpThemeUnchanged) {
				tmpThemeOrig.setting[this.selectedLevel.name] =
					this.groupThemeCopy.setting[this.selectedLevel.name];
				await this.createTheme({
					params: tmpThemeOrig.setting,
					themeName: tmpThemeOrig.name,
				});
				this.setGroupTheme({
					group_id: this.$route.params.jackpot_group_id,
					themeName: tmpThemeOrig.name,
				});
			}

			let tmpJackpot_config_id = this.selectedJBConfig.id;
			if (this.selectedJBConfig.id < 0) {
				tmpJackpot_config_id = (
					await this.addJBConfigNew(this.selectedJBConfigCopy)
				).id;
			}

			this.newJokerBonusCopy.jackpot_config_id = tmpJackpot_config_id;
			this.newJokerBonusCopy.level_id = this.selectedLevel.id;

			await this.createJokerBonus(this.newJokerBonusCopy);
			await this.loadJackpotGroupDetail(this.$route.params.jackpot_group_id);

			await this.loadFilteredGroupJackpots({
				groupId: this.$route.params.jackpot_group_id,
				filterParams: { win_stamp: { eq: null } },
				queryParams: { per_page: 50, order: "desc" },
			});
			await this.loadGroupThemeSetting({
				group_id: this.$route.params.jackpot_group_id,
				themeName: this.bonusGroupName,
			});
			await this.loadSpecificTheme(
				this.jackpotGroupDetail.data.data.theme_name
			); // this.bonusGroupName } )

			this.cancelDialog();
			this.$emit("jokerAdd");
		},

		makeJokerBonusConfigList(configsList) {
			this.jokerBonusConfigsList = [];
			this.jokerBonusConfigsList = JSON.parse(JSON.stringify(configsList));
			this.jokerBonusConfigsList.unshift(this.blankConfig);
		},

		async loadData() {
			this.selectedJBConfig = this.blankConfig;
			await this.fillJBConfigList();
		},

		async fillJBConfigList() {
			let tmpContribType =
				this.groupContribType == "bet" ? "contrib_ratio" : "contrib_bet_range";
			await this.loadJBConfigs({
				[tmpContribType]: { eq: null },
				deleted: { eq: null },
			});

			if (this.getFilteredJBConfigsList) {
				this.makeJokerBonusConfigList(this.getFilteredJBConfigsList);
			} else {
				console.warn(
					" get filtered jackpot config list data data are empty, no data in data in data"
				);
			}
		},

		async loadJBConfigs(payloadData) {
			await this.loadFilteredJBConfigs({
				filter: payloadData,
				queryParams: { order_by: "deleted", per_page: 4000 },
			});
		},

		copyNConvertTheme(tmpTheme) {
			let tmpGroupThemeCopy = {};
			tmpGroupThemeCopy.setting = {};

			tmpGroupThemeCopy.id = tmpTheme.id;
			tmpGroupThemeCopy.name = tmpTheme.name;

			for (let tmpLevelName in tmpTheme.setting) {
				tmpGroupThemeCopy.setting[tmpLevelName] = this.copyNConvertScheme(
					tmpTheme.setting[tmpLevelName]
				);
			}
			return tmpGroupThemeCopy;
		},

		copyNConvertScheme(tmpScheme) {
			return JSON.parse(JSON.stringify(tmpScheme));
		},

		async nextStep(n) {
			switch (n) {
				case 1:
					this.formGuide++;
					break;
				case 2:
					this.formGuide++;
					await this.loadJackpotGroupDetail(
						this.$route.params.jackpot_group_id
					);
					this.loadFilteredJackpotGroupList({
						bodyPayload: {
							theme_name: { eq: this.groupThemeCopy.name },
							deleted: { eq: null },
						},
						queryParams: { per_page: 100 },
					});
					this.sameThemeGroups = this.getFilteredJackpotGroupList.data; //.data
					await this.fillTheme();

					break;
			}
		},

		previousStep(n) {
			if (n !== 1) {
				this.formGuide = n - 1;
			}
		},

		async fillTheme() {
			await this.loadSpecificTheme(
				this.jackpotGroupDetail.data.data.theme_name
			);
			if (
				this.getSpecificTheme?.data &&
				Object.keys(this.getSpecificTheme.data.setting).length > 0
			) {
				this.groupThemeCopy = JSON.parse(
					JSON.stringify(this.getSpecificTheme.data)
				);
			}
		},

		createNewTheme() {
			let tmpTheme = {};
			tmpTheme["name"] = this.bonusGroupName;
			tmpTheme["setting"] = {};
			this.levels.forEach((level) => {
				tmpTheme.setting[level.name] = this.getDefaultScheme[level.name]
					? JSON.parse(JSON.stringify(this.getDefaultScheme[level.name]))
					: JSON.parse(JSON.stringify(this.getDefaultScheme.blankScheme));
			});
			return tmpTheme;
		},

		JBConfigUpdate([val, valid]) {
			this.setCurrentStepValid(valid);
		},
		JBUpdate([val, valid]) {
			this.setCurrentStepValid(valid);
		},
		setCurrentStepValid(valid) {
			const tmp = [...this.isStepValid];
			tmp[this.formGuide - 1] = valid;
			this.isStepValid = tmp;
		},
		onSelectedJBConfig(e) {
			this.selectedJBConfigCopy = { ...e };
		},

		isJokerBonusComplete() {
			let tmpSubmit = true;
			this.isStepValid.forEach((step) => {
				if (!step) {
					tmpSubmit = false;
				}
			});
			this.jbFormToSubmit = tmpSubmit;
		},

		equalObjects(o1, o2) {
			if (Object.keys(o1).length != Object.keys(o2).length) {
				return false;
			}

			for (let key in o1) {
				if (!Object.keys(o2).includes(key)) {
					return false;
				}

				if (typeof o1[key] !== typeof o2[key]) {
					return false;
				}
				switch (typeof o1[key]) {
					case "object": {
						if (!this.equalObjects(o1[key], o2[key])) {
							return false;
						}
						break;
					}
					default: {
						if (o1[key] !== o2[key]) {
							return false;
						}
					}
				}
			}
			return true;
		},
		resetJokerBonusCopy() {
			this.newJokerBonusCopy = { ...this.newJokerBonus };
		},
	},

	watch: {
		selectedLevel(newValue, oldValue) {
			let tmpName = newValue.name?.toLowerCase();
			if (newValue.id > -1) {
				this.isStepValid[this.formGuide - 1] = true;
			}
			this.isJokerBonusComplete();
		},
	},
	created() {
		this.resetJokerBonusCopy();
	},
};
</script>
<style>
.stickyButtons {
	background-color: var(--v-kajot-anti-text-base);
	position: sticky;
	z-index: 1;
	bottom: 0;
}
</style>
