<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			width="500">
			<template
				v-slot:activator="{ on, attrs }"
				v-if="
					_usrFlagsSome({
						key: 'bonus.groups.detail',
						val: permissions.EDIT,
					})
				">
				<v-btn
					:color="color"
					elevation="1"
					fab
					small
					v-bind="attrs"
					v-on="on"
					@click="repopulateForm">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="headline primary">Edit Group</v-card-title>

				<v-card-text class="pa-8">
					<v-form
						ref="form"
						v-model="valid"
						@submit="submit">
						<v-text-field
							label="Group Name"
							v-model="groupName"
							:rules="[
								rules.required,
								rules.no_spaces,
								rules.minMaxLength(1, 32),
							]"
							type="text" />
						<br />
						<v-divider />
						<br />

						<v-row>
							<v-col class="col-6 py-4 justify-center align-center text-center">
								<span>Current Group Config ID: {{ groupConfigID }}</span>
							</v-col>
							<v-select
								v-if="list"
								:items="list"
								v-model="selectedGroupConfig"
								item-value="id"
								item-text="id"
								label="Select Group Config"
								:rules="[rules.required]"
								return-object
								class="col-6" />
							<div v-else>Group config list error</div>
						</v-row>
						<fieldset class="pa-3">
							<legend class="body-1 px-2">Config Preview</legend>
							<v-text-field
								label="Contribution"
								v-model="contrib"
								:suffix="contribTypeId === 1 ? '%' : ''"
								disabled />
							<v-text-field
								label="Selected Contribution Type"
								v-model="contribType.contribType"
								disabled></v-text-field>
							<v-text-field
								label="Currency"
								v-model="currency"
								:items="currencyCodes"
								disabled />
						</fieldset>
					</v-form>
				</v-card-text>

				<v-divider />

				<v-card-actions>
					<v-btn
						text
						@click="dialog = false">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="submit"
						:disabled="createdJackpotGroup.isLoading">
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { rules as defaultRules } from "./../../plugins/rules.ts";
import { currencyCodes } from "../../constants/currencyCodes";
import permissions from "../../mixins/permissions";

export default {
	props: {
		group_ID: {
			type: Number,
		},
		oldGroupName: {
			type: String,
		},
		groupConfigID: {
			type: Number,
		},
		oldContribution: {
			type: Number,
		},
		oldContribType: {
			type: String,
		},
		oldContribTypeId: {
			type: Number,
		},
		oldCurrency: {
			type: String,
		},
		color: {
			type: String,
			default: "primary",
		},
	},
	mixins: [permissions],
	data() {
		return {
			groupName: "",
			groupId: 0,
			contrib: 0,
			contribTypeId: 0,
			contribTypeDropdown: [
				{ id: 1, contribType: "Percentage" },
				{ id: 2, contribType: "Bet" },
			],
			selectedGroupConfig: "",
			contribType: "",
			currency: "",
			currencyCodes,
			valid: true,
			rules: {
				...defaultRules,

				currency: (v) =>
					this.currencyCodes.includes(v) || "Must be a valid currency code",
			},
			dialog: false,
			blockForm: false,
			groupsUsingConfig: [],
			textWarningColor: "black",
		};
	},
	computed: {
		...mapGetters(["createdJackpotGroup"]),
		...mapGetters("jackpotGroup2", {
			groupList: "list",
		}),
		...mapGetters("jackpotGroupConfig2", {
			list: "list",
		}),
	},

	methods: {
		...mapActions([
			"loadJackpotGroupDetail",
			"loadSpecificGroupConfig",
			"updateJackpotGroupSettings",
			"updateGroupConfiguration",
			"updateSpecificGroupConfig",
			"filterGroupConfigs",
		]),

		...mapActions("jackpotGroupConfig2", {
			loadConfigs: "loadList",
		}),

		...mapActions("jackpotGroup2", {
			loadGroups: "loadGroupsWithConfig",
		}),

		async submit() {
			if (!this.$refs.form.validate()) {
				console.warn(" FORM IS MISSING SOME VALUES !,\nno data were sent");
			}

			let tmpChanges = false;

			if (this.groupName != this.oldGroupName) {
				await this.updateJackpotGroupSettings({
					groupId: this.group_ID,
					bodyPayload: { name: this.groupName },
				});
			}

			if (this.groupConfigID == this.selectedGroupConfig.id) {
				let tmpNewValues = {};

				if (this.contrib != this.oldContribution) {
					tmpChanges = true;
					const [int, dec] = this.contrib.toString().split(".");
					tmpNewValues["contrib"] = parseFloat(
						(this.contrib / 100).toFixed(
							(int?.length || 0) + (dec?.length || 0)
						)
					); //  this.contrib / 100
				}

				if (this.currency != this.oldCurrency) {
					tmpChanges = true;
					tmpNewValues["currency"] = this.currency;
				}

				if (tmpChanges) {
					await this.updateSpecificGroupConfig({
						groupConfigId: this.groupConfigID,
						bodyPayload: tmpNewValues,
					});
				} else {
					console.warn(
						" NO CHANGE IN GROUP DATA WAS MADE !,\nno data were sent"
					);
				}
			}

			if (this.groupConfigID != this.selectedGroupConfig.id) {
				await this.updateJackpotGroupSettings({
					groupId: this.groupId,
					bodyPayload: { group_config_id: this.selectedGroupConfig.id },
				});
			}

			// await this.loadJackpotGroupDetail(this.group_ID);
			await this.loadSpecificGroupConfig(this.selectedGroupConfig.id);
			this.dialog = false;
			this.$emit("editGroup");
		},

		async repopulateForm() {
			this.groupName = this.oldGroupName;
			this.groupId = this.group_ID;
			this.contribTypeId = this.oldContribTypeId;

			await this.loadAllConfigs();

			let tmpGroupConf = this.list.find(
				(element) => element.id == this.groupConfigID
			);

			this.contrib = tmpGroupConf?.contrib;

			this.contribTypeId = tmpGroupConf.contrib_type_id;
			this.contribType = this.contribTypeDropdown.find(
				(el) => el.id == tmpGroupConf.contrib_type_id
			);
			this.currency = tmpGroupConf.currency;
			this.selectedGroupConfig = tmpGroupConf;
		},

		async loadAllConfigs() {
			await this.loadConfigs({
				payload: { contrib_type_id: { eq: this.contribTypeId } },
				query: { per_page: 5000, page: 1 },
			});
		},

		async getListOfGroupsWithConfig(configId) {
			await this.loadGroups({ groupConfigId: configId });
			this.groupsUsingConfig = [];
			this.groupList.forEach((element) => this.groupsUsingConfig.push(element));
			this.textWarningColor = this.groupsUsingConfig.length > 1 ? "error" : "";
		},
	},

	watch: {
		selectedGroupConfig(newV, oldV) {
			this.blockForm = newV.id == this.groupConfigID ? false : true;
			this.getListOfGroupsWithConfig(newV.id);
			this.contrib = newV.contrib;
			this.contribTypeId = newV.contrib_type_id;
			this.contribType = this.contribTypeDropdown.find(
				(el) => el.id == newV.contrib_type_id
			);
			this.currency = newV.currency;
		},
	},

	created() {},
};
</script>
