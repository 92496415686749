<template>
	<v-container
		id="bonusGroupDetail"
		fluid
		tag="section">
		<v-row dense>
			<v-col cols="12">
				<KeyValueCard2
					:item="configFullDetail"
					:title="detail.name"
					:cols="filteredDetailCols"
					:loading="loading">
					<template v-slot:heading>
						<card-heading
							title="Group Settings"
							:color="'secondary'">
							<div class="d-inline-flex">
								<edit-jackpot-group
									:group_ID="detail.id"
									:groupConfigID="config.id"
									:oldGroupName="detail.name"
									:oldContribution="config.contrib"
									:oldContribType="configFullDetail.contrib_type"
									:oldContribTypeId="configFullDetail.contrib_type_id"
									:oldCurrency="config.currency"
									color="primary"
									@editGroup="reloadData()" />
								<v-dialog
									v-if="shouldShowDeleteButton"
									v-model="dialogDelete"
									max-width="500px">
									<template v-slot:activator="{ on: dialog, attrs }">
										<v-tooltip
											color="error darken-2"
											bottom>
											<template v-slot:activator="{ on: tooltip }">
												<span v-on="tooltip">
													<v-btn
														:disabled="!shouldEnableDeleteButton"
														fab
														color="error"
														small
														link
														elevation="1"
														v-on="dialog"
														v-bind="attrs"
														class="ml-2">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</span>
											</template>
											<span v-if="!shouldEnableDeleteButton">
												Deleting group in only possible with no active jackpots.
											</span>
											<span v-else>Delete</span>
										</v-tooltip>
									</template>

									<v-card>
										<v-toolbar class="red d-flex white--text font-weight-bold">
											Delete
										</v-toolbar>
										<v-card-text>
											Are you sure you want to delete this Group ?
										</v-card-text>
										<v-card-actions class="justify-space-between">
											<v-btn
												text
												@click="closeDelete">
												NO
											</v-btn>

											<v-btn
												color="error"
												text
												@click="deactivateButton">
												YES
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>

								<v-dialog
									v-if="shouldShowReactivateButton"
									v-model="dialogReactivate"
									max-width="500px">
									<template v-slot:activator="{ on: dialog, attrs }">
										<v-tooltip
											bottom
											color="error darken-2">
											<template v-slot:activator="{ on: tooltip }">
												<span v-on="tooltip">
													<v-btn
														fab
														small
														elevation="1"
														color="error"
														link
														v-on="dialog"
														v-bind="attrs"
														class="ml-2">
														<v-icon>mdi-restore</v-icon>
													</v-btn>
												</span>
											</template>
											<span>Restore</span>
										</v-tooltip>
									</template>

									<v-card>
										<v-toolbar class="error d-flex font-weight-bold">
											Restore
										</v-toolbar>
										<v-card-text>
											Are you sure you want to restore this Group ?
										</v-card-text>
										<v-card-actions class="justify-space-between">
											<v-btn
												text
												@click="closeReactivate">
												NO
											</v-btn>

											<v-btn
												color="error"
												text
												@click="reactivateButton">
												YES
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</div>
						</card-heading>
					</template>
					<template #[`item.contrib.value`]="{ item }">
						{{ item.contrib }} {{ item.contrib_type_id === 1 ? "%" : "" }}
					</template>
					<template #[`item.group_config_id.value`]="{ item }">
						{{ item.group_config_id }}
						<v-icon
							v-if="
								_usrFlagsSome({
									key: 'bonus.group_configs.detail',
									val: permissions.EDIT,
								})
							"
							@click="onEditItem()"
							size="20px"
							class="ml-2 mr-1 primary--text">
							mdi-pencil
						</v-icon>
					</template>
				</KeyValueCard2>
			</v-col>
		</v-row>

		<v-row dense>
			<v-col>
				<v-card
					icon="mdi-clipboard-text"
					class="pa-3"
					v-if="!loading">
					<card-heading
						color="secondary"
						:title="`Levels`">
						<template
							v-if="
								_usrFlagsSome({
									key: 'bonus.groups.detail',
									val: permissions.EDIT,
								})
							"
							v-slot:default>
							<AddJackpot
								:bonusGroupId="detail.id"
								:bonusGroupName="detail.name"
								:groupContribType="configFullDetail.contrib_type"
								:unusedLevelList="unusedLevels"
								:levels="levels"
								color="primary"
								@jokerAdd="reloadData()" />
						</template>
					</card-heading>
					<span v-if="jackpotList.length > 0">
						<v-data-table
							class="mb-6"
							:item-class="`${itemClass}`"
							@click:row="handleJackpotClick"
							@mouseenter:row="handleJackpotHover"
							@mouseleave:row="handleJackpotHover"
							:items="jackpotList"
							:items-per-page="-1"
							hide-default-footer
							:headers="filteredJackpotsMeta">
							<template #item="{ headers, item, on, attrs }">
								<tr
									style="cursor: pointer"
									v-bind="attrs"
									v-on="on"
									:style="`${
										hoveredLevel === item.level_id
											? $vuetify.theme.dark
												? 'background-color: var(--v-primary-darken2)'
												: 'background-color: var(--v-primary-lighten2)'
											: ''
									}`">
									<td
										:align="header.align"
										v-for="header of headers"
										:key="header.name">
										<span v-if="header.value === 'contrib_ratio'">
											{{ item.contrib_ratio | Placeholder(0) | DecimalPoints }}%
										</span>
										<span v-else-if="header.value === 'created'">
											{{ item.created | Date }}
										</span>
										<span v-else-if="header.value === 'main_value'">
											{{
												item.main_value
													| CurrencyFromCredits()
													| Number({ currency: config.currency })
											}}
										</span>
										<span v-else>{{ item[header.value] }}</span>
									</td>
								</tr>
							</template>
						</v-data-table>
						<v-row
							class="d-flex justify-space-around"
							v-if="showTheme && theme != undefined">
							<v-col
								class="text-center"
								cols="12"
								md="6"
								lg="3"
								v-for="level in jackpotList"
								:key="level.id">
								<v-hover
									v-if="theme.setting[level.level_name]"
									style="position: relative"
									v-slot="{ hover }">
									<div
										@mouseenter="(e) => handleJackpotHover(e, { item: level })"
										@mouseleave="(e) => handleJackpotHover(e, { item: level })"
										class="px-2"
										style="cursor: pointer"
										@click="handleJackpotClick(level)"
										:class="{
											selectedLevel: hover || hoveredLevel === level.level_id,
											dark: $vuetify.theme.dark,
										}">
										<LevelPreview
											style="position: relative; z-index: 1"
											previewWidth="100vw"
											previewHeight="7vh"
											previewBorderWidth="3px"
											:valueText="level.main_value | CurrencyFromCredits()"
											:valueCurrency="config.currency"
											:iconUrl="theme.setting[level.level_name].iconUrl"
											:iconTint="theme.setting[level.level_name].iconTint"
											:backgroundColors="
												theme.setting[level.level_name].backgroundColors
											"
											:backgroundColorStops="
												theme.setting[level.level_name].backgroundColorStops
											"
											:textColors="theme.setting[level.level_name].textColors"
											:textColorStops="
												theme.setting[level.level_name].textColorStops
											"
											:borderColors="
												theme.setting[level.level_name].borderColors
											"
											:borderColorStops="
												theme.setting[level.level_name].borderColorStops
											"
											:visible_name="
												theme.setting[level.level_name].visible_name
											"
											:image_name="level.level_name"></LevelPreview>
										<span style="position: relative; z-index: 1">
											{{ theme.setting[level.level_name].iconUrl }}
										</span>
									</div>
								</v-hover>
								<div
									v-else
									class="text-center">
									no scheme set for this Joker Bonus
								</div>
							</v-col>
						</v-row>
						<v-row
							v-else-if="showTheme"
							class="text-center">
							<v-col>No Group Theme</v-col>
						</v-row>
					</span>
					<v-card-text
						v-else
						class="text-center">
						No Active Joker Bonus
					</v-card-text>
				</v-card>

				<template v-else>
					<v-skeleton-loader type="card" />
				</template>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col
				v-if="showTheme"
				cols="12"
				md="5">
				<v-card
					icon="mdi-clipboard-text"
					class="pa-3"
					v-if="!loading">
					<card-heading
						color="secondary"
						:title="`Group Theme: ${
							theme.name ? theme.name.toUpperCase() : ''
						}`">
						<template
							v-if="showTheme"
							v-slot:default>
							<div class="d-inline-flex">
								<v-btn
									:to="{
										name: 'group-theme',
										params: {
											jackpot_group_id: detail.id,
											jackpot_group_name: detail.name,
											group_theme_name: theme.name,
											breadcrumbs: {
												title: 'Edit theme',
											},
										},
									}"
									elevation="1"
									fab
									small
									color="primary">
									<v-icon
										v-if="
											_usrFlagsSome({
												key: 'bonus.groups.detail',
												val: permissions.EDIT,
											}) && detail.theme_name
										">
										mdi-pencil
									</v-icon>
									<v-icon
										v-else-if="
											_usrFlagsSome({
												key: 'bonus.groups.detail',
												val: permissions.EDIT,
											})
										">
										mdi-plus
									</v-icon>
									<v-icon
										v-else-if="
											_usrFlagsSome({
												key: 'bonus.groups.detail',
												val: permissions.READ,
											}) && detail.theme_name
										">
										mdi-eye
									</v-icon>
								</v-btn>
							</div>
						</template>
					</card-heading>
					<span v-if="theme != undefined">
						<v-row
							class="pa-2 text-center"
							v-bind:key="theme.id">
							<v-col
								cols="12"
								lg="6"
								class="ma-0"
								v-for="(level, level_name) in filteredLevels"
								:key="`${level_name}${level.visible_name}`">
								<LevelPreview
									previewWidth="100vw"
									previewHeight="7vh"
									previewBorderWidth="3px"
									:iconUrl="level.iconUrl"
									:iconTint="level.iconTint"
									:backgroundColors="level.backgroundColors"
									:backgroundColorStops="level.backgroundColorStops"
									:textColors="level.textColors"
									:textColorStops="level.textColorStops"
									:borderColors="level.borderColors"
									:borderColorStops="level.borderColorStops"
									:visible_name="level.visible_name"
									:image_name="level_name"></LevelPreview>
								{{ theme && theme.setting[level_name].iconUrl }}
							</v-col>
						</v-row>
					</span>
					<v-card-text
						v-else
						class="text-center">
						No Group Theme Set
					</v-card-text>
				</v-card>

				<template v-else>
					<v-skeleton-loader type="card" />
				</template>
			</v-col>
			<v-col
				cols="12"
				md="4">
				<v-card
					icon="mdi-clipboard-text"
					class="pa-3"
					v-if="!loading">
					<card-heading
						color="secondary"
						title="Assigned Casinos">
						<template
							v-if="
								_usrFlagsSome({
									key: 'bonus.groups.detail',
									val: permissions.EDIT,
								})
							"
							v-slot:default>
							<EditComponent
								noModel
								:fullArray="allowedCasinos"
								:selectedArray="assignedCasinosNormalized"
								:loading="false"
								valueKey="casino_id"
								:searchIn="[`casino_id`]"
								labelKey="website"
								:width="500"
								title="Edit Casino List"
								placeholderText="No casino selected yet"
								color="primary"
								v-on:submitEditComponent="onEditCasinosSubmit">
								<template #appendItem="{ item }">
									<v-chip>ID: {{ item.casino_id }}</v-chip>
								</template>
							</EditComponent>
						</template>
					</card-heading>
					<v-data-table
						:options.sync="options"
						:headers="casinosMeta"
						:items="assignedCasinosNormalized"></v-data-table>
				</v-card>

				<template v-else>
					<v-skeleton-loader type="card" />
				</template>
			</v-col>
			<!-- TODO: [L2-103] ASSIGNED GAMES/CASINOS PAGINATION -->
			<v-col
				cols="12"
				md="3">
				<v-card
					icon="mdi-clipboard-text"
					class="pa-3"
					v-if="!loading">
					<card-heading
						title="Assigned Games"
						color="secondary">
						<template
							v-if="
								_usrFlagsSome({
									key: 'bonus.groups.detail',
									val: permissions.EDIT,
								})
							"
							v-slot:default>
							<EditComponent
								:selectedArray="assignedGamesNormalized"
								:fullArray="sortedGames"
								:loading="false"
								:width="500"
								noModel
								valueKey="technical"
								labelKey="game_name"
								title="Edit Games List"
								placeholderText="No Games selected yet"
								color="primary"
								v-on:submitEditComponent="onEditGamesSubmit">
								<template #appendItem="{ item }">
									<v-chip
										small
										v-if="item.removed">
										Removed
									</v-chip>
								</template>
							</EditComponent>
						</template>
					</card-heading>
					<v-data-table
						:headers="filteredTableHeaders"
						:options.sync="options"
						ref="gameTable"
						:hide-default-footer="hideTableFooter"
						:items="assignedGamesNormalizedTable"
						:items-per-page="6">
						<template #[`item.removed`]="{ item }">
							<v-chip
								small
								v-if="item.removed">
								Removed
							</v-chip>
						</template>
					</v-data-table>
				</v-card>

				<template v-else>
					<v-skeleton-loader type="card" />
				</template>
			</v-col>
		</v-row>
		<GroupConfigEdit
			:item="editedItem"
			:open="addEditDialog"
			@submit="onAddEditSubmit"
			@cancel="onAddSubmitCancel"></GroupConfigEdit>
		<!-- <v-row dense>
		</v-row> -->
	</v-container>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import GroupConfigEdit from "../../components/forms/GroupConfigEdit.vue";
import EditComponent from "../../components/jackpots/EditComponent.vue";
import EditJackpotGroup from "../../components/jackpots/EditJackpotGroup.vue";
import AddJackpot from "../../components/jackpots/jackpot/AddJackpot.vue";
import cardHeading from "../../components/shared/CardHeading.vue";
import LevelPreview from "../../components/shared/LevelPreview.vue";
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";

export default {
	components: {
		KeyValueCard2,
		AddJackpot,
		EditComponent,
		EditJackpotGroup,
		cardHeading,
		LevelPreview,
		GroupConfigEdit,
	},
	mixins: [permissions, table],
	data() {
		return {
			hoveredLevel: undefined,

			noOptions: true,
			editedItem: {},
			addEditDialog: false,
			rowIsClickable: true,
			loading: true,
			headers: [
				{
					align: "center",
					value: "game_name",
					text: "Name",
				},
				{
					align: "center",
					value: "removed",
					text: "",
					hide: () => !this.showRemoved,
				},
			],
			casinosMeta: [
				{ text: "Website", value: "website", align: "center" },
				{ text: "Country", value: "country", align: "center" },
			],
			ready: false,
			dialogDelete: false,
			dialogReactivate: false,
			detailRows: [
				[
					{ label: "Group Name", key: "name" },
					{ label: "Group Config ID", key: "group_config_id" },
				],
				[
					{ label: "Contribution", key: "contrib" },
					{ label: "Contribution Type", key: "contrib_type" },
				],
				[
					{ label: "Contribution Type Id", key: "contrib_type_id" },
					{ label: "Currency", key: "currency" },
				],
			],
		};
	},

	computed: {
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
		}),
		...mapGetters("jackpotGroup2", {
			detail: "detail",
		}),
		...mapGetters("jackpotTheme2", {
			theme: "detail",
		}),
		...mapGetters("jackpot2", {
			jackpotList: "list",
		}),
		...mapGetters("jackpotGroupConfig2", {
			config: "detail",
		}),
		...mapGetters("meta2", {
			games: "allowedGames",
		}),
		...mapGetters("jackpotLevel2", {
			levels: "list",
		}),
		...mapGetters({
			allowedCasinos: "allowedCasinos",
		}),
		filteredLevels() {
			return Object.keys(this.theme.setting).reduce((agg, curr) => {
				if (
					this.theme.setting[curr].borderColors &&
					this.theme.setting[curr].borderColors.length > 0
				) {
					agg[curr] = this.theme.setting[curr];
				}
				return agg;
			}, {});
		},
		showRemoved() {
			return this.assignedGamesNormalizedTable
				.slice(
					this.options.itemsPerPage * (this.options.page - 1),
					this.options.itemsPerPage * this.options.page
				)
				.some((el) => el.removed);
		},
		filteredTableHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
		sortedGames() {
			return [...this.games].sort((a, b) => {
				if (a.technical > b.technical) return 1;
				return -1;
			});
		},
		configFullDetail() {
			return {
				name: this.detail.name,
				group_config_id: this.detail.group_config_id,
				contrib: this.config.contrib,
				contrib_type: this.config.contrib_type_id === 1 ? "percentage" : "bet",
				contrib_type_id: this.config.contrib_type_id,
				currency: this.config.currency,
			};
		},
		filteredDetailCols() {
			return this.detailRows
				.filter((el) => {
					const res = !el.hide?.();
					return res;
				})
				.map((el) => (Array.isArray(el) ? el : el.items));
		},
		showTheme() {
			return this._usrFlagsSome({
				key: "bonus.group_themes.detail",
				val: this.permissions.READ,
			});
		},
		hideTableFooter() {
			return this.detail.games.length <= 6;
		},
		assignedGamesNormalized() {
			const res = this.detail.games
				.filter((game) => this.games.find((el) => el.technical == game))
				.map((game) => this.games.find((el) => el.technical === game))
				.sort((a, b) => {
					if (a.technical > b.technical) return 1;
					return -1;
				});
			return res;
		},
		assignedGamesNormalizedTable() {
			return this.detail.games
				.map((game) => this.games.find((el) => el.technical === game))
				.filter((el) => !!el)
				.sort((a, b) => {
					if (a.technical > b.technical) return 1;
					return -1;
				});
		},
		assignedCasinosNormalized() {
			return this.detail.casinos
				.map((casino) =>
					this.allowedCasinos.find((el) => el.casino_id === casino)
				)
				.filter((el) => !!el);
		},
		unusedLevels() {
			return this.levels.filter(
				(level) =>
					!this.jackpotList.some((jackpot) => jackpot.level_name === level.name)
			);
		},
		filteredJackpotsMeta() {
			return this.jackpotsMeta.filter((el) => !el.hide?.());
		},
		jackpotsMeta() {
			return [
				{
					text: "Level",
					value: "level_name",
					align: "center",
				},
				{
					text: "Contrib Ratio",
					value: "contrib_ratio",
					align: "center",
					hide: () => this.configFullDetail.contrib_type_id != 1,
				},
				{
					text: "Contrib Bet Range",
					value: "contrib_bet_range",
					align: "center",
					hide: () => this.configFullDetail.contrib_type_id != 2,
				},
				{
					text: "Created",
					value: "created",
					align: "center",
				},
				{
					text: "Main value ", // + this.detail.currency,
					value: "main_value",
					align: "center",
				},
			];
			// rowLink: {
			// 	pageName: "jackpot-detail",
			// 	urlParam: "jackpot_id",
			// 	urlValue: "id",
			// },
		},

		getGroupSettings() {
			return {
				headers: [
					{ text: "Setting Name", value: "key" },
					{ text: "Setting Value", value: "value" },
				],
				items: [
					{
						key: "Group name",
						value: this.detail.name,
					},
					{ key: "Group Config ID", value: this.configFullDetail.id },
					{ key: "Contribution", value: `${this.configFullDetail.contrib} %` },
					{
						key: "Contribution Type",
						value: this.configFullDetail.contrib_type_id == 1 ? "bet" : "%",
					},
					{ key: "Contribution Type Id", value: this.config.contrib_type_id },
					{ key: "Currency", value: this.configFullDetail.currency },
				],
			};
		},

		shouldShowDeleteButton() {
			return (
				this.ready &&
				this._usrFlagsSome({
					key: "bonus.groups.detail",
					val: this.permissions.DELETE,
				}) &&
				this.detail.deleted === null
			);
		},
		shouldEnableDeleteButton() {
			return this.ready && this.jackpotList.length === 0;
		},

		shouldShowReactivateButton() {
			return this.ready && this.detail.deleted !== null;
		},
	},

	// TODO: [L2-104] ADD BACK BUTTON

	methods: {
		async onEditItem() {
			this.editedItem = { ...this.config };
			this.addEditDialog = true;
		},
		async onAddSubmitCancel() {
			this.editedItem = {};
			this.addEditDialog = false;
		},
		async onAddEditSubmit() {
			this.editedItem = {};
			this.addEditDialog = false;
			await this.reloadData();
		},
		...mapActions("jackpotGroup2", {
			loadDetail: "loadDetail",
			deactivateGroup: "delete",
			reactivateGroup: "unDelete",
		}),
		...mapActions("jackpotTheme2", {
			loadTheme: "loadDetail",
		}),
		...mapActions("jackpot2", {
			loadJackpotList: "loadListByGroup",
		}),
		...mapActions("jackpotGroupConfig2", {
			deleteItem: "delete",
			restoreItem: "unDelete",
			createItem: "create",
			updateItem: "update",
		}),
		...mapActions("jackpotGroupConfig2", {
			loadConfig: "loadDetail",
		}),
		...mapActions("jackpotLevel2", {
			loadLevels: "loadList",
		}),
		...mapActions(["setCasinosInGroup", "setGamesInGroup"]),
		async onEditGamesSubmit(newSelection) {
			const uneditable = _.difference(
				this.detail.games,
				this.games.map((el) => el.technical)
			);
			await this.setGamesInGroup({
				groupId: this.detail.id,
				games: [...newSelection, ...uneditable],
			});
			await this.reloadData();
		},
		async onEditCasinosSubmit(newSelection) {
			const uneditable = _.difference(
				this.detail.casinos,
				this.allowedCasinos.map((el) => el.casino_id)
			);
			await this.setCasinosInGroup({
				groupId: this.detail.id,
				casinos: [...newSelection, ...uneditable],
			});
			await this.reloadData();
		},

		async deactivateButton() {
			await this.deactivateGroup(this.detail.id);
			this.closeDelete();
			await this.reloadData();
		},
		async reactivateButton() {
			await this.reactivateGroup(this.detail.id);
			this.closeReactivate();
			await this.reloadData();
		},

		closeReactivate() {
			this.dialogReactivate = false;
		},
		closeDelete() {
			this.dialogDelete = false;
		},

		async reloadData() {
			//load detail first to check if group exists
			await this.loadDetail(this.$route.params.jackpot_group_id);

			if (this.detail === null) {
				//if group doesn't exist, navigate to 404 page
				this.$router.push({ name: "404" });
				return;
			}

			this.loading = true;

			//load as much data in parallel as possible to save loading time
			await Promise.all([
				this.loadLevels({
					query: { per_page: 5000, page: 1 },
					payload: { deleted: { eq: null } },
				}),
				this.loadConfig(this.detail.group_config_id),
				this.loadTheme(this.detail.theme_name),
				this.loadJackpotList(this.$route.params.jackpot_group_id),
			]);
			this.loading = false;
		},
		handleJackpotHover(e, row) {
			if (e.type === "mouseenter") {
				this.hoveredLevel = row.item.level_id;
			} else {
				if (this.hoveredLevel === row.item.level_id) {
					this.hoveredLevel = undefined;
				}
			}
		},
		handleJackpotClick(row) {
			this.$router.push({
				name: "jackpot-detail",
				params: {
					jackpot_id: row.id,
					breadcrumbs: {
						title: `${row.level_name} jackpot`,
					},
				},
			});
		},
	},

	async created() {
		await this.reloadData();
		this.ready = true;
	},
};
</script>

<style>
.selectedLevel::before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--v-primary-lighten2);
	border-radius: 5px;
}

.selectedLevel.dark::before {
	background-color: var(--v-primary-darken2);
}
</style>
