var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "col-8 ml-auto mr-auto",
          attrs: { width: _vm.dialogWidth },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: _vm.color,
                            elevation: "1",
                            fab: "",
                            small: "",
                          },
                          on: { click: _vm.loadData },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _vm.unusedLevelList
                ? _c("v-card-title", { staticClass: "headline primary" }, [
                    _vm._v(" Add Bonus Level "),
                  ])
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { elevation: "0" },
                      model: {
                        value: _vm.formGuide,
                        callback: function ($$v) {
                          _vm.formGuide = $$v
                        },
                        expression: "formGuide",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        { staticClass: "px-10" },
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.isStepValid[0],
                                editable: _vm.tmpEditable,
                                step: 1,
                              },
                            },
                            [_vm._v(" Joker Config ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.isStepValid[1],
                                editable: _vm.tmpEditable,
                                step: 2,
                              },
                            },
                            [_vm._v(" Joker Bonus ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.isStepValid[2],
                                editable: _vm.tmpEditable,
                                step: 3,
                              },
                            },
                            [_vm._v(" Joker Scheme ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        {
                          staticClass: "d-flex justify-center align-center",
                          style: "min-height: " + _vm.stepperItemsHeight,
                        },
                        [
                          _c(
                            "v-stepper-content",
                            {
                              key: "jokerConfigForm",
                              staticClass: "pa-2 pa-md-4",
                              attrs: { step: 1 },
                            },
                            [
                              _vm.formGuide == 1
                                ? _c(
                                    "v-card",
                                    { staticClass: "pa-2 ma-0" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-self-start",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                xl: "6",
                                              },
                                            },
                                            [
                                              _vm.jokerBonusConfigsList
                                                ? _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.jokerBonusConfigsList,
                                                      "item-value": "id",
                                                      "item-text": "name",
                                                      label:
                                                        "Select Jackpot Config",
                                                      rules: [
                                                        _vm.rules.required,
                                                      ],
                                                      "return-object": "",
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onSelectedJBConfig,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedJBConfig,
                                                      callback: function ($$v) {
                                                        _vm.selectedJBConfig =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedJBConfig",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "5",
                                                md: "4",
                                                lg: "3",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value: _vm._f("Capitalize")(
                                                    _vm.groupContribType
                                                  ),
                                                  disabled: "",
                                                  label: "Contribution Type",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append-outer",
                                                      fn: function () {
                                                        return [
                                                          _c("Tooltip", {
                                                            attrs: {
                                                              iconSize: "22px",
                                                              text: `Inherited from group config`,
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3682091073
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.jokerBonusListByConfgId.length > 1
                                            ? _c(
                                                "v-col",
                                                [
                                                  _vm._v(
                                                    " Joker Bonus List using the same config: "
                                                  ),
                                                  _vm._l(
                                                    _vm.jokerBonusListByConfgId,
                                                    function (jokerBonus) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: jokerBonus.id,
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " id : " +
                                                              _vm._s(
                                                                jokerBonus.id
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("JokerBonusConfigForm", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          value: _vm.selectedJBConfigCopy,
                                          contribTypeOfGroup:
                                            _vm.groupContribType,
                                          disableForm: _vm.preMadeConfig,
                                        },
                                        on: { input: _vm.JBConfigUpdate },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { key: "jokerBonusForm", attrs: { step: 2 } },
                            [
                              _vm.formGuide == 2
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "col-12 col-md-6 col-lg-5 col-xl-4 pa-4 mx-auto my-auto d-flex flex-column justify-space-around",
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Min Value",
                                                  disabled: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedJBConfigCopy
                                                      .min_value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.selectedJBConfigCopy,
                                                      "min_value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedJBConfigCopy.min_value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Max Value",
                                                  disabled: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedJBConfigCopy
                                                      .max_value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.selectedJBConfigCopy,
                                                      "max_value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedJBConfigCopy.max_value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Min Bonus",
                                                  disabled: "",
                                                  type: "number",
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedJBConfigCopy
                                                      .min_jack,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.selectedJBConfigCopy,
                                                      "min_jack",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedJBConfigCopy.min_jack",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("joker-bonus-form", {
                                        attrs: { value: _vm.newJokerBonusCopy },
                                        on: { input: _vm.JBUpdate },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              key: "jokerPreviewForm",
                              staticClass: "pa-4 pa-md-2",
                              attrs: { step: 3 },
                            },
                            [
                              _vm.formGuide == 3
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "d-flex align-start flex-wrap justify-center text-center px-0 py-4",
                                      attrs: {
                                        "min-height": _vm.stepperContentHeight,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { color: "error darken-2" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on: tooltip }) {
                                                  return [
                                                    _vm.sameThemeGroups.length >
                                                    1
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "align-center mt-4",
                                                              attrs: {
                                                                fab: "",
                                                                elevation: "0",
                                                                color: "red",
                                                                "x-small": "",
                                                              },
                                                            },
                                                            tooltip
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-exclamation"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2085369146
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _c("h4", [
                                              _vm._v(
                                                "Groups using this theme:"
                                              ),
                                            ]),
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.sameThemeGroups,
                                                function (groups) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: groups.id,
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(groups.name) +
                                                          " (ID: " +
                                                          _vm._s(groups.id) +
                                                          ") "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.levelDropdown
                                        ? _c("v-select", {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-4 px-4 pt-4 ma-0",
                                            attrs: {
                                              items: _vm.levelDropdown,
                                              "item-value": "name",
                                              "item-text": "name",
                                              label: "Select Joker Bonus Level",
                                              rules: [_vm.rules.required],
                                              "return-object": "",
                                            },
                                            model: {
                                              value: _vm.selectedLevel,
                                              callback: function ($$v) {
                                                _vm.selectedLevel = $$v
                                              },
                                              expression: "selectedLevel",
                                            },
                                          })
                                        : _c(
                                            "div",
                                            { staticClass: "col-2 pa-4" },
                                            [
                                              _vm._v(
                                                " THERE ARE NO FREE LEVELS TO CREATE FORM "
                                              ),
                                            ]
                                          ),
                                      _c("v-spacer"),
                                      _c("edit-scheme", {
                                        staticClass: "col-12",
                                        attrs: {
                                          schemeToEdit:
                                            _vm.groupThemeCopy.setting[
                                              _vm.selectedLevel.name
                                            ],
                                          schemeName: _vm.selectedLevel.name,
                                        },
                                        on: {
                                          borderChange: _vm.updateBorder,
                                          backgroundChange:
                                            _vm.updateBackground,
                                          textChange: _vm.updateText,
                                          iconTintChange: _vm.updateIconTint,
                                          animationTintChange:
                                            _vm.updateAnimationTint,
                                          textStrokeChange:
                                            _vm.updateTextStroke,
                                          visibleNameChange:
                                            _vm.updateVisibleName,
                                          iconUrlChange: _vm.updateIconUrl,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "stickyButtons", attrs: { col: "12" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.cancelDialog } },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "info",
                        text: "",
                        disabled: _vm.formGuide < 2,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previousStep(_vm.formGuide)
                        },
                      },
                    },
                    [_vm._v(" Back ")]
                  ),
                  _vm.formGuide === 3
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            disabled: !_vm.jbFormToSubmit,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.isStepValid[_vm.formGuide - 1],
                          },
                          on: {
                            click: function ($event) {
                              return _vm.nextStep(_vm.formGuide)
                            },
                          },
                        },
                        [_vm._v(" Next ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }