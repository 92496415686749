var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "bonusGroupDetail", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("KeyValueCard2", {
                attrs: {
                  item: _vm.configFullDetail,
                  title: _vm.detail.name,
                  cols: _vm.filteredDetailCols,
                  loading: _vm.loading,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "heading",
                      fn: function () {
                        return [
                          _c(
                            "card-heading",
                            {
                              attrs: {
                                title: "Group Settings",
                                color: "secondary",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-inline-flex" },
                                [
                                  _c("edit-jackpot-group", {
                                    attrs: {
                                      group_ID: _vm.detail.id,
                                      groupConfigID: _vm.config.id,
                                      oldGroupName: _vm.detail.name,
                                      oldContribution: _vm.config.contrib,
                                      oldContribType:
                                        _vm.configFullDetail.contrib_type,
                                      oldContribTypeId:
                                        _vm.configFullDetail.contrib_type_id,
                                      oldCurrency: _vm.config.currency,
                                      color: "primary",
                                    },
                                    on: {
                                      editGroup: function ($event) {
                                        return _vm.reloadData()
                                      },
                                    },
                                  }),
                                  _vm.shouldShowDeleteButton
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "500px" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({
                                                  on: dialog,
                                                  attrs,
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "error darken-2",
                                                          bottom: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on: tooltip,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      tooltip
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    !_vm.shouldEnableDeleteButton,
                                                                                  fab: "",
                                                                                  color:
                                                                                    "error",
                                                                                  small:
                                                                                    "",
                                                                                  link: "",
                                                                                  elevation:
                                                                                    "1",
                                                                                },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          dialog
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-delete"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        !_vm.shouldEnableDeleteButton
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " Deleting group in only possible with no active jackpots. "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v("Delete"),
                                                            ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1340190624
                                          ),
                                          model: {
                                            value: _vm.dialogDelete,
                                            callback: function ($$v) {
                                              _vm.dialogDelete = $$v
                                            },
                                            expression: "dialogDelete",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  staticClass:
                                                    "red d-flex white--text font-weight-bold",
                                                },
                                                [_vm._v(" Delete ")]
                                              ),
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Are you sure you want to delete this Group ? "
                                                ),
                                              ]),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass:
                                                    "justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: _vm.closeDelete,
                                                      },
                                                    },
                                                    [_vm._v(" NO ")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.deactivateButton,
                                                      },
                                                    },
                                                    [_vm._v(" YES ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.shouldShowReactivateButton
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "500px" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({
                                                  on: dialog,
                                                  attrs,
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          bottom: "",
                                                          color:
                                                            "error darken-2",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on: tooltip,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      tooltip
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs:
                                                                                {
                                                                                  fab: "",
                                                                                  small:
                                                                                    "",
                                                                                  elevation:
                                                                                    "1",
                                                                                  color:
                                                                                    "error",
                                                                                  link: "",
                                                                                },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          dialog
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-restore"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Restore"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2710905030
                                          ),
                                          model: {
                                            value: _vm.dialogReactivate,
                                            callback: function ($$v) {
                                              _vm.dialogReactivate = $$v
                                            },
                                            expression: "dialogReactivate",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  staticClass:
                                                    "error d-flex font-weight-bold",
                                                },
                                                [_vm._v(" Restore ")]
                                              ),
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Are you sure you want to restore this Group ? "
                                                ),
                                              ]),
                                              _c(
                                                "v-card-actions",
                                                {
                                                  staticClass:
                                                    "justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click:
                                                          _vm.closeReactivate,
                                                      },
                                                    },
                                                    [_vm._v(" NO ")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.reactivateButton,
                                                      },
                                                    },
                                                    [_vm._v(" YES ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.contrib.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(item.contrib) +
                              " " +
                              _vm._s(item.contrib_type_id === 1 ? "%" : "") +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.group_config_id.value`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(" " + _vm._s(item.group_config_id) + " "),
                          _vm._usrFlagsSome({
                            key: "bonus.group_configs.detail",
                            val: _vm.permissions.EDIT,
                          })
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-2 mr-1 primary--text",
                                  attrs: { size: "20px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEditItem()
                                    },
                                  },
                                },
                                [_vm._v(" mdi-pencil ")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              !_vm.loading
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-3",
                      attrs: { icon: "mdi-clipboard-text" },
                    },
                    [
                      _c("card-heading", {
                        attrs: { color: "secondary", title: `Levels` },
                        scopedSlots: _vm._u(
                          [
                            _vm._usrFlagsSome({
                              key: "bonus.groups.detail",
                              val: _vm.permissions.EDIT,
                            })
                              ? {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("AddJackpot", {
                                        attrs: {
                                          bonusGroupId: _vm.detail.id,
                                          bonusGroupName: _vm.detail.name,
                                          groupContribType:
                                            _vm.configFullDetail.contrib_type,
                                          unusedLevelList: _vm.unusedLevels,
                                          levels: _vm.levels,
                                          color: "primary",
                                        },
                                        on: {
                                          jokerAdd: function ($event) {
                                            return _vm.reloadData()
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm.jackpotList.length > 0
                        ? _c(
                            "span",
                            [
                              _c("v-data-table", {
                                staticClass: "mb-6",
                                attrs: {
                                  "item-class": `${_vm.itemClass}`,
                                  items: _vm.jackpotList,
                                  "items-per-page": -1,
                                  "hide-default-footer": "",
                                  headers: _vm.filteredJackpotsMeta,
                                },
                                on: {
                                  "click:row": _vm.handleJackpotClick,
                                  "mouseenter:row": _vm.handleJackpotHover,
                                  "mouseleave:row": _vm.handleJackpotHover,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({
                                        headers,
                                        item,
                                        on,
                                        attrs,
                                      }) {
                                        return [
                                          _c(
                                            "tr",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  style: `${
                                                    _vm.hoveredLevel ===
                                                    item.level_id
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "background-color: var(--v-primary-darken2)"
                                                        : "background-color: var(--v-primary-lighten2)"
                                                      : ""
                                                  }`,
                                                },
                                                "tr",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            _vm._l(headers, function (header) {
                                              return _c(
                                                "td",
                                                {
                                                  key: header.name,
                                                  attrs: {
                                                    align: header.align,
                                                  },
                                                },
                                                [
                                                  header.value ===
                                                  "contrib_ratio"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "DecimalPoints"
                                                              )(
                                                                _vm._f(
                                                                  "Placeholder"
                                                                )(
                                                                  item.contrib_ratio,
                                                                  0
                                                                )
                                                              )
                                                            ) +
                                                            "% "
                                                        ),
                                                      ])
                                                    : header.value === "created"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("Date")(
                                                                item.created
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : header.value ===
                                                      "main_value"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("Number")(
                                                                _vm._f(
                                                                  "CurrencyFromCredits"
                                                                )(
                                                                  item.main_value
                                                                ),
                                                                {
                                                                  currency:
                                                                    _vm.config
                                                                      .currency,
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item[header.value]
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4064758443
                                ),
                              }),
                              _vm.showTheme && _vm.theme != undefined
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "d-flex justify-space-around",
                                    },
                                    _vm._l(_vm.jackpotList, function (level) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: level.id,
                                          staticClass: "text-center",
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "3",
                                          },
                                        },
                                        [
                                          _vm.theme.setting[level.level_name]
                                            ? _c("v-hover", {
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ hover }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                              class: {
                                                                selectedLevel:
                                                                  hover ||
                                                                  _vm.hoveredLevel ===
                                                                    level.level_id,
                                                                dark: _vm
                                                                  .$vuetify
                                                                  .theme.dark,
                                                              },
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              on: {
                                                                mouseenter: (
                                                                  e
                                                                ) =>
                                                                  _vm.handleJackpotHover(
                                                                    e,
                                                                    {
                                                                      item: level,
                                                                    }
                                                                  ),
                                                                mouseleave: (
                                                                  e
                                                                ) =>
                                                                  _vm.handleJackpotHover(
                                                                    e,
                                                                    {
                                                                      item: level,
                                                                    }
                                                                  ),
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleJackpotClick(
                                                                      level
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "LevelPreview",
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "relative",
                                                                    "z-index":
                                                                      "1",
                                                                  },
                                                                  attrs: {
                                                                    previewWidth:
                                                                      "100vw",
                                                                    previewHeight:
                                                                      "7vh",
                                                                    previewBorderWidth:
                                                                      "3px",
                                                                    valueText:
                                                                      _vm._f(
                                                                        "CurrencyFromCredits"
                                                                      )(
                                                                        level.main_value
                                                                      ),
                                                                    valueCurrency:
                                                                      _vm.config
                                                                        .currency,
                                                                    iconUrl:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ].iconUrl,
                                                                    iconTint:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .iconTint,
                                                                    backgroundColors:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .backgroundColors,
                                                                    backgroundColorStops:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .backgroundColorStops,
                                                                    textColors:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .textColors,
                                                                    textColorStops:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .textColorStops,
                                                                    borderColors:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .borderColors,
                                                                    borderColorStops:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .borderColorStops,
                                                                    visible_name:
                                                                      _vm.theme
                                                                        .setting[
                                                                        level
                                                                          .level_name
                                                                      ]
                                                                        .visible_name,
                                                                    image_name:
                                                                      level.level_name,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "relative",
                                                                    "z-index":
                                                                      "1",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .theme
                                                                          .setting[
                                                                          level
                                                                            .level_name
                                                                        ]
                                                                          .iconUrl
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            : _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " no scheme set for this Joker Bonus "
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm.showTheme
                                ? _c(
                                    "v-row",
                                    { staticClass: "text-center" },
                                    [_c("v-col", [_vm._v("No Group Theme")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("v-card-text", { staticClass: "text-center" }, [
                            _vm._v(" No Active Joker Bonus "),
                          ]),
                    ],
                    1
                  )
                : [_c("v-skeleton-loader", { attrs: { type: "card" } })],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _vm.showTheme
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  !_vm.loading
                    ? _c(
                        "v-card",
                        {
                          staticClass: "pa-3",
                          attrs: { icon: "mdi-clipboard-text" },
                        },
                        [
                          _c("card-heading", {
                            attrs: {
                              color: "secondary",
                              title: `Group Theme: ${
                                _vm.theme.name
                                  ? _vm.theme.name.toUpperCase()
                                  : ""
                              }`,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.showTheme
                                  ? {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-inline-flex" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "group-theme",
                                                      params: {
                                                        jackpot_group_id:
                                                          _vm.detail.id,
                                                        jackpot_group_name:
                                                          _vm.detail.name,
                                                        group_theme_name:
                                                          _vm.theme.name,
                                                        breadcrumbs: {
                                                          title: "Edit theme",
                                                        },
                                                      },
                                                    },
                                                    elevation: "1",
                                                    fab: "",
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._usrFlagsSome({
                                                    key: "bonus.groups.detail",
                                                    val: _vm.permissions.EDIT,
                                                  }) && _vm.detail.theme_name
                                                    ? _c("v-icon", [
                                                        _vm._v(" mdi-pencil "),
                                                      ])
                                                    : _vm._usrFlagsSome({
                                                        key: "bonus.groups.detail",
                                                        val: _vm.permissions
                                                          .EDIT,
                                                      })
                                                    ? _c("v-icon", [
                                                        _vm._v(" mdi-plus "),
                                                      ])
                                                    : _vm._usrFlagsSome({
                                                        key: "bonus.groups.detail",
                                                        val: _vm.permissions
                                                          .READ,
                                                      }) &&
                                                      _vm.detail.theme_name
                                                    ? _c("v-icon", [
                                                        _vm._v(" mdi-eye "),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm.theme != undefined
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      key: _vm.theme.id,
                                      staticClass: "pa-2 text-center",
                                    },
                                    _vm._l(
                                      _vm.filteredLevels,
                                      function (level, level_name) {
                                        return _c(
                                          "v-col",
                                          {
                                            key: `${level_name}${level.visible_name}`,
                                            staticClass: "ma-0",
                                            attrs: { cols: "12", lg: "6" },
                                          },
                                          [
                                            _c("LevelPreview", {
                                              attrs: {
                                                previewWidth: "100vw",
                                                previewHeight: "7vh",
                                                previewBorderWidth: "3px",
                                                iconUrl: level.iconUrl,
                                                iconTint: level.iconTint,
                                                backgroundColors:
                                                  level.backgroundColors,
                                                backgroundColorStops:
                                                  level.backgroundColorStops,
                                                textColors: level.textColors,
                                                textColorStops:
                                                  level.textColorStops,
                                                borderColors:
                                                  level.borderColors,
                                                borderColorStops:
                                                  level.borderColorStops,
                                                visible_name:
                                                  level.visible_name,
                                                image_name: level_name,
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.theme &&
                                                    _vm.theme.setting[
                                                      level_name
                                                    ].iconUrl
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-card-text",
                                { staticClass: "text-center" },
                                [_vm._v(" No Group Theme Set ")]
                              ),
                        ],
                        1
                      )
                    : [_c("v-skeleton-loader", { attrs: { type: "card" } })],
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              !_vm.loading
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-3",
                      attrs: { icon: "mdi-clipboard-text" },
                    },
                    [
                      _c("card-heading", {
                        attrs: {
                          color: "secondary",
                          title: "Assigned Casinos",
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._usrFlagsSome({
                              key: "bonus.groups.detail",
                              val: _vm.permissions.EDIT,
                            })
                              ? {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("EditComponent", {
                                        attrs: {
                                          noModel: "",
                                          fullArray: _vm.allowedCasinos,
                                          selectedArray:
                                            _vm.assignedCasinosNormalized,
                                          loading: false,
                                          valueKey: "casino_id",
                                          searchIn: [`casino_id`],
                                          labelKey: "website",
                                          width: 500,
                                          title: "Edit Casino List",
                                          placeholderText:
                                            "No casino selected yet",
                                          color: "primary",
                                        },
                                        on: {
                                          submitEditComponent:
                                            _vm.onEditCasinosSubmit,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "appendItem",
                                              fn: function ({ item }) {
                                                return [
                                                  _c("v-chip", [
                                                    _vm._v(
                                                      "ID: " +
                                                        _vm._s(item.casino_id)
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3846141862
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("v-data-table", {
                        attrs: {
                          options: _vm.options,
                          headers: _vm.casinosMeta,
                          items: _vm.assignedCasinosNormalized,
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : [_c("v-skeleton-loader", { attrs: { type: "card" } })],
            ],
            2
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              !_vm.loading
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-3",
                      attrs: { icon: "mdi-clipboard-text" },
                    },
                    [
                      _c("card-heading", {
                        attrs: { title: "Assigned Games", color: "secondary" },
                        scopedSlots: _vm._u(
                          [
                            _vm._usrFlagsSome({
                              key: "bonus.groups.detail",
                              val: _vm.permissions.EDIT,
                            })
                              ? {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("EditComponent", {
                                        attrs: {
                                          selectedArray:
                                            _vm.assignedGamesNormalized,
                                          fullArray: _vm.sortedGames,
                                          loading: false,
                                          width: 500,
                                          noModel: "",
                                          valueKey: "technical",
                                          labelKey: "game_name",
                                          title: "Edit Games List",
                                          placeholderText:
                                            "No Games selected yet",
                                          color: "primary",
                                        },
                                        on: {
                                          submitEditComponent:
                                            _vm.onEditGamesSubmit,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "appendItem",
                                              fn: function ({ item }) {
                                                return [
                                                  item.removed
                                                    ? _c(
                                                        "v-chip",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v(" Removed ")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3000166288
                                        ),
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("v-data-table", {
                        ref: "gameTable",
                        attrs: {
                          headers: _vm.filteredTableHeaders,
                          options: _vm.options,
                          "hide-default-footer": _vm.hideTableFooter,
                          items: _vm.assignedGamesNormalizedTable,
                          "items-per-page": 6,
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.removed`,
                              fn: function ({ item }) {
                                return [
                                  item.removed
                                    ? _c("v-chip", { attrs: { small: "" } }, [
                                        _vm._v(" Removed "),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                : [_c("v-skeleton-loader", { attrs: { type: "card" } })],
            ],
            2
          ),
        ],
        1
      ),
      _c("GroupConfigEdit", {
        attrs: { item: _vm.editedItem, open: _vm.addEditDialog },
        on: { submit: _vm.onAddEditSubmit, cancel: _vm.onAddSubmitCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }